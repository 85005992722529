import { render, staticRenderFns } from "./bindUserForm.vue?vue&type=template&id=4bc67060&scoped=true"
import script from "./bindUserForm.vue?vue&type=script&lang=js"
export * from "./bindUserForm.vue?vue&type=script&lang=js"
import style0 from "./bindUserForm.vue?vue&type=style&index=0&id=4bc67060&prod&lang=less"
import style1 from "./bindUserForm.vue?vue&type=style&index=1&id=4bc67060&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bc67060",
  null
  
)

export default component.exports